//
// Google font - Poppins
//

@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: normal;
    src:  url('../../../fonts/IRANSansWeb.eot');
    src:  url('../../../fonts/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('../../../fonts/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('../../../fonts/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('../../../fonts/IRANSansWeb.ttf') format('truetype');
  }