//
// components-rtl.scss
//

// dropdown
html[data-lang-dir="RTL"] {

.dropdown-megamenu{
  &.show{
    left: 20px!important;
  }
}


// icons

.icon-list-demo{
  i{
    margin-left: 12px;
    margin-right: 0;
  }
}


// Breadcrumb

.breadcrumb-item + .breadcrumb-item::before {
  float: right;
  padding-left: 0.5rem;
  padding-right: 0;
}




// Invoice

@media print {
  .content-page,
  .content,
  body {
      margin-right: 0;
  }
}

// Demos button 
.demos-show-btn {
  left: 0;
  right: auto;
  border-radius: 0 6px 6px 0;
}

}