// 
// general-rtl.scss
//
html[data-lang-dir="RTL"] {

    html {
        direction: rtl;
    }

    body {
        text-align: right;
    }
}
html[data-lang-dir="RTL"] {
    direction: rtl;
}